export class FileKeyProperties {
  id: number;
  key: FilePropertyKey;
  values: FilePropertyValue[];
}

export class FilePropertyKey {
  id: number;
  global: boolean;
  key: string;
}

export class FilePropertyValue {
  id: number;
  key: string;
  value: string;
}

export class FilePropertyRequest {
  valueId: number;
}

export class PageableResponse<T> {
  content: T[];
  page: {
    first: boolean;
    last: boolean;
    totalPages: number;
    totalElements: number;
    numberOfElements: number;
    size: number;
    number: number;
  };
  sort: { property: string; direction: string }[];
}

export interface PageableResponse<T> {
  content: T[];
  page: {
    first: boolean;
    last: boolean;
    totalPages: number;
    totalElements: number;
    numberOfElements: number;
    size: number;
    number: number;
  };
  sort: { property: string; direction: string }[];
}


